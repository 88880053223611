import React, { useContext, useEffect, useState } from "react";
import { Formik, Field, FieldArray, Form } from "formik";
import { navigate } from "gatsby";
import { GoogleSpreadsheet } from "google-spreadsheet";

import Layout from "../components/layout";
import SEO from "../components/seo";
import medicalResearch from "../images/undraw_medical_research.svg";
import moment from "moment";
import Text from "../components/Text";

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider";
import Question from "../components/Question";
import ErrorModal from "../components/ErrorModal";
import Button from "../components/Button";

function IndexPage() {
  const state = useContext(GlobalStateContext) || {};
  const dispatch = useContext(GlobalDispatchContext);

  const { language } = state;

  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");

  const SPREADSHEET_ID = process.env.GATSBY_SPREADSHEET_ID;
  const SHEET_ID = process.env.GATSBY_SHEET_ID;
  const CLIENT_EMAIL = process.env.GATSBY_CLIENT_EMAIL;
  const PRIVATE_KEY = process.env.GATSBY_PRIVATE_KEY;

  const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

  // enumerate the class select options
  const classListTNTT = [
    ["", "N/A"],
    ["AN-SC", "Ấu Nhi Sơ Cấp"],
    ["AN-C1", "Ấu Nhi Cấp 1"],
    ["AN-C2", "Ấu Nhi Cấp 2"],
    ["AN-C3", "Ấu Nhi Cấp 3"],
    ["TN-C1", "Thiếu Nhi Cấp 1"],
    ["TN-C2", "Thiếu Nhi Cấp 2"],
    ["TN-C3", "Thiếu Nhi Cấp 3"],
    ["NS-C1", "Nghĩa Sĩ Cấp 1"],
    ["NS-C2", "Nghĩa Sĩ Cấp 2"],
    ["NS-C3", "Nghĩa Sĩ Cấp 3"],
    ["HS-C1", "Hiệp Sĩ Cấp 1"],
    ["HS-C2", "Hiệp Sĩ Cấp 2"],
    ["HT", "Huynh Trưởng"],
  ];

  const classListGL = [
    ["", "N/A"],
    ["1", "1"],
    ["2A", "2A"],
    ["2B", "2B"],
    ["3A", "3A"],
    ["3B", "3B"],
    ["4A", "4A"],
    ["4B", "4B"],
    ["5A", "5A"],
    ["5B", "5B"],
    ["6", "6"],
    ["7", "7"],
    ["8A", "8A"],
    ["8B", "8B"],
    ["9", "9"],
    ["10", "10"],
    ["11", "11"],
    ["12", "12"],
    ["teacher", "Teacher"],
  ];

  const classListVN = [
    ["", "N/A"],
    ["1", "1"],
    ["2", "2"],
    ["3", "3"],
    ["4", "4"],
    ["5", "5"],
    ["6", "6"],
    ["7", "7"],
    ["8", "8"],
    ["9", "9"],
    ["teacher", "Teacher"],
  ];

  useEffect(() => {
    dispatch({ type: "SET_NAMES", payload: [] });
    dispatch({ type: "SET_SUBMITTED", payload: false });
    dispatch({ type: "SET_SUCCESS", payload: false });
    // setSelectedDate(
    //   moment(getNextDate(new Date().getDay() === 6 ? 6 : 0)).format(
    //     "YYYY-MM-DD"
    //   )
    // );
    setSelectedDate(moment().format("YYYY-MM-DD"));
  }, []); //eslint-disable-line

  const getNextDate = (day) => {
    // const dayINeed = 0; // for Sunday
    const today = moment().isoWeekday();

    // if we haven't yet passed the day of the week that I need:
    if (today <= day) {
      // then just give me this week's instance of that day
      return moment().isoWeekday(day);
    } else {
      // otherwise, give me *next week's* instance of that same day
      return moment().add(1, "weeks").isoWeekday(day);
    }
  };

  const day = new Date().getDay();

  const timeOpen = moment("00:00", "hh:mm");
  const timeClosed = moment("12:00", "hh:mm");

  // Only allow submissions on Saturday and Sunday between 12am and 12pm
  // const formOpen =
  //   (day === 0 || day === 6) && moment().isBetween(timeOpen, timeClosed);

  // to test on closed days
  const formOpen = true;

  return (
    <Layout>
      <SEO
        keywords={[`Don Bosco`, `COVID`, `screening`]}
        title="DB COVID Screening"
      />

      <Formik
        initialValues={{
          names: [
            {
              firstName: "",
              lastName: "",
              classTNTT: "",
              classGL: "",
              classVN: "",
              other: false,
            },
          ],
          // question1: undefined,
          question2: undefined,
          question3: undefined,
          question4: undefined,
        }}
        validate={(values) => {
          const errors = {};
          // if (!values.question1) {
          //   errors.question1 = "Required";
          // }
          if (!values.question2) {
            errors.question2 = "Required";
          }
          if (!values.question3) {
            errors.question3 = "Required";
          }
          if (!values.question4) {
            errors.question4 = "Required";
          }

          values.names.forEach((name) => {
            if (name.firstName === "" || name.lastName === "") {
              errors.names = "Please enter name";
            }

            // ensure each kid has at least one class selected
            if (
              moment(selectedDate).day() === 0 &&
              (typeof name.classTNTT === "undefined" ||
                name.classTNTT === "") &&
              (typeof name.classGL === "undefined" || name.classGL === "") &&
              !name.other
            ) {
              errors.names = "Please select class";
            }
            if (
              moment(selectedDate).day() === 6 &&
              (typeof name.classVN === "undefined" || name.classVN === "") &&
              !name.other
            ) {
              errors.names = "Please select class";
            }
          });

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          if (formOpen) {
            setSubmitting(true);

            const appendSpreadsheet = async (values) => {
              try {
                await doc.useServiceAccountAuth({
                  client_email: CLIENT_EMAIL,
                  private_key: PRIVATE_KEY.replace(/\\n/g, "\n"),
                });

                // loads document properties and worksheets
                await doc.loadInfo();

                const sheet = doc.sheetsById[SHEET_ID];

                await Promise.all(
                  values.names.map(async (name) => {
                    const allNo =
                      // values.question1 === "no" &&
                      values.question2 === "no" &&
                      values.question3 === "no" &&
                      values.question4 === "no";
                    const newRow = {
                      "First Name": name.firstName,
                      "Last Name": name.lastName,
                      TNTT: name.classTNTT || "",
                      GL: name.classGL || "",
                      VN: name.classVN || "",
                      "Date Submitted": moment().format("YYYY-MM-DD"),
                      "Time Submitted": moment().format("HH:mm"),
                      "Date Applied": moment(selectedDate).format("YYYY-MM-DD"),
                      "Form Status": allNo.toString(),
                      // "Question 1": values.question1,
                      "Question 2": values.question2,
                      "Question 3": values.question3,
                      "Question 4": values.question4,
                      Other: name.other || false,
                    };
                    // console.log(
                    //   `adding row for ${name.firstName} ${name.lastName}`
                    // );
                    return await sheet.addRow(newRow);
                  })
                );

                // console.log("results", results);

                dispatch({ type: "SET_NAMES", payload: values.names });
                dispatch({ type: "SET_SUBMITTED", payload: true });
                const allNo =
                  // values.question1 === "no" &&
                  values.question2 === "no" &&
                  values.question3 === "no" &&
                  values.question4 === "no";

                dispatch({ type: "SET_SUCCESS", payload: allNo });
                dispatch({
                  type: "SET_DATE_APPLIED",
                  payload: moment(selectedDate).format("dddd, MMMM Do, YYYY"),
                });
                setSubmitting(false);
                navigate("/summary");
              } catch (e) {
                console.error("Error: ", e);
                setShowModal(true);
                setSubmitting(false);
              }
            };

            appendSpreadsheet(values);
          } else {
            console.log("form closed");
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          validateForm,
          setFieldValue,
          isSubmitting,
          isValid,
          dirty,
          /* and other goodies */
        }) => (
          <>
            <div className="container mx-auto py-8 px-8 md:px-16">
              <section className="mb-16">
                <img
                  alt="doctor"
                  className="block w-1/2 mx-auto mb-4"
                  src={medicalResearch}
                />

                {!formOpen ? (
                  <div
                    className="bg-teal-200 border-t-4 border-teal-700 rounded-b text-teal-darkest px-4 py-3 shadow-md my-2 mb-4"
                    role="alert"
                  >
                    <div className="flex">
                      <svg
                        className="h-6 w-6 text-teal mr-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                      </svg>
                      <div>
                        <p className="font-bold">
                          The form is currently closed.
                        </p>
                        <p className="text-sm">
                          The form will open between 12:00am and 12:00pm on
                          Saturday and Sunday.
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}

                <p className="leading-loose">
                  <Text
                    en={`Please fill out and submit the form before coming to church. You may add additional students from the same household.`}
                    vn={`Xin vui lòng điền vào mẫu đơn và nộp lên trước khi đến nhà thờ.  Quý vị có thể điền thêm tên các học sinh trong cùng một gia đình.`}
                  />
                </p>
                {/* <p className="leading-loose mt-2">
                  <Text
                    en={`A paper version of the form can be found here: `}
                    vn={`Mẫu đơn bằng giấy để in ra thì nhấn vào đây: `}
                  />
                  <a
                    className="text-blue-700 hover:text-blue-800"
                    href="/HealthScreeningQuestions.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Health Screening Form
                  </a>{" "}
                </p> */}
              </section>

              <div className="mb-3">
                <label
                  htmlFor="date"
                  className="block mb-2 text-xs font-bold uppercase"
                >
                  Date
                </label>

                {/* <select //eslint-disable-line
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer"
                  id="selectedDate"
                  value={selectedDate}
                  onChange={(e) => {
                    setSelectedDate(e.target.value);
                    validateForm();
                    setFieldValue("question1", undefined);
                    setFieldValue("question2", undefined);
                    setFieldValue("question3", undefined);
                    setFieldValue("question4", undefined);
                  }}
                >
                  <option value={moment(getNextDate(0)).format("YYYY-MM-DD")}>
                    {moment(getNextDate(0)).format("dddd, MMMM D")} - (TNTT, GL)
                  </option>
                  <option value={moment(getNextDate(6)).format("YYYY-MM-DD")}>
                    {moment(getNextDate(6)).format("dddd, MMMM D")} - (VN)
                  </option>
                </select> */}
                <p className="text-xl font-semibold">
                  {moment(selectedDate).format("dddd, MMMM D")}
                </p>
              </div>

              <section>
                <Form className="mx-auto ">
                  <FieldArray
                    name="names"
                    render={(arrayHelpers) => (
                      <div>
                        <div>
                          {values.names.map((name, index) => (
                            <div
                              key={index}
                              className="w-full bg-gray-100 mb-4 p-2 rounded border-gray-200"
                            >
                              <div className="w-full flex space-x-3">
                                <div className="w-1/2">
                                  <label
                                    htmlFor={`names[${index}].firstName`}
                                    className="block mb-2 text-xs font-bold uppercase"
                                  >
                                    <Text en="First Name" vn="Tên" />
                                  </label>
                                  <Field
                                    name={`names[${index}].firstName`}
                                    id={`names[${index}].firstName`}
                                    className="w-full  form-input"
                                    placeholder="First Name"
                                  />
                                </div>
                                <div className="w-1/2">
                                  <label
                                    htmlFor={`names[${index}].lastName`}
                                    className="block mb-2 text-xs font-bold uppercase"
                                  >
                                    <Text en="Last Name" vn="Họ" />
                                  </label>
                                  <Field
                                    name={`names[${index}].lastName`}
                                    id={`names[${index}].lastName`}
                                    className="w-full  form-input"
                                    placeholder="Last Name"
                                  />
                                </div>
                                {index !== 0 && (
                                  <button
                                    type="button"
                                    title="Remove Student"
                                    className="bg-red-700 hover:bg-red-600  h-6 text-white font-bold px-2 mb-auto rounded transition duration-100"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    X
                                  </button>
                                )}
                              </div>
                              <div className="w-full flex space-x-3 mt-2">
                                {moment(selectedDate).day() !== 6 ? (
                                  <>
                                    <div className="mb-3 mt-2 w-full">
                                      <label
                                        htmlFor="date"
                                        className="block mb-2 text-xs font-bold uppercase"
                                      >
                                        TNTT Cấp
                                      </label>
                                      <Field
                                        as="select"
                                        name={`names[${index}].classTNTT`}
                                        id={`names[${index}].classTNTT`}
                                        className="block appearance-none w-full text-xs sm:text-sm bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer"
                                      >
                                        {classListTNTT.map((tnttclass) => (
                                          <option
                                            key={tnttclass[0]}
                                            value={tnttclass[0]}
                                          >
                                            {tnttclass[1]}
                                          </option>
                                        ))}
                                      </Field>
                                    </div>
                                    {/* <div className="mb-3 mt-2 w-full">
                                      <label
                                        htmlFor="gl"
                                        className="block mb-2 text-xs font-bold uppercase"
                                      >
                                        <Text
                                          en="Giáo Lý Class"
                                          vn="Lớp Giáo Lý"
                                        />
                                      </label>
                                      <Field
                                        as="select"
                                        name={`names[${index}].classGL`}
                                        id={`names[${index}].classGL`}
                                        className="block appearance-none w-full text-xs sm:text-sm bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer"
                                      >
                                        {classListGL.map((glclass) => (
                                          <option
                                            key={glclass[0]}
                                            value={glclass[0]}
                                          >
                                            {glclass[1]}
                                          </option>
                                        ))}
                                      </Field>
                                    </div> */}
                                  </>
                                ) : (
                                  <div className="mb-3 mt-2 w-full">
                                    <label
                                      htmlFor="vn"
                                      className="block mb-2 text-xs font-bold uppercase"
                                    >
                                      <Text
                                        en="Việt Ngữ Class"
                                        vn="Lớp Việt Ngữ"
                                      />
                                    </label>
                                    <Field
                                      as="select"
                                      name={`names[${index}].classVN`}
                                      id={`names[${index}].classVN`}
                                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer"
                                    >
                                      {classListVN.map((vnclass) => (
                                        <option
                                          key={vnclass[0]}
                                          value={vnclass[0]}
                                        >
                                          {vnclass[1]}
                                        </option>
                                      ))}
                                    </Field>
                                  </div>
                                )}
                                {!values.names[index].classTNTT &&
                                !values.names[index].classListGL &&
                                !values.names[index].classVN ? (
                                  <div className="my-auto pt-2 pr-2">
                                    <label className="flex items-center">
                                      <Field
                                        type="checkbox"
                                        className="form-checkbox md:h-6 md:w-6"
                                        name={`names[${index}].other`}
                                      />
                                      <span
                                        className="ml-2"
                                        title="Hoi Phu Huynh, Ban Trat Tu, RCIA, volunteer, etc"
                                      >
                                        Other
                                      </span>
                                    </label>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          ))}
                          <button
                            type="button"
                            className="bg-blue-700 hover:bg-blue-600 shadow text-white text-xs sm:text-sm px-2 my-auto rounded transition duration-100 flex items-center"
                            onClick={() =>
                              arrayHelpers.push({ firstName: "", lastName: "" })
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              className="h-5 mr-1"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                                clipRule="evenodd"
                              />
                            </svg>
                            <Text
                              className="py-1"
                              en="Add additional student"
                              vn="Thêm học sinh khác trong cùng gia đình"
                            />
                          </button>
                        </div>
                      </div>
                    )}
                  />

                  <ol className="list-decimal mt-6">
                    {/* <li className="my-2">
                      <Question
                        name="question1"
                        questionText={
                          language === "en"
                            ? `Have you participated in any mass gatherings that include more than 10 people outside the household WITHOUT the use of face masks and social distancing protocols in the past 2 weeks?`
                            : `Trong vòng 2 tuần vừa qua, quý vị đã có tham dự vào những đám đông nào có trên 10 người không đeo khẩu trang và không áp dụng phương cách hạn chế tiếp xúc xã hội hay không?`
                        }
                      />
                    </li> */}

                    <li className="my-2">
                      <Question
                        name="question2"
                        questionText={
                          language === "en"
                            ? `Have you experienced any of the following symptoms within the past 2 weeks?
                            🤒 Fever or chills
                            🤭 Cough
                            😩 Shortness of breath or difficulty breathing
                            😪 Fatigue
                            🤕 Muscle or body aches
                            🤯 Headache
                            😷 New loss of taste or smell
                            😷 Sore throat
                            🤧 Congestion or runny nose
                            🤮 Nausea or vomiting
                            🤢 Diarrhea`
                            : `Trong vòng 2 tuần vừa qua, quý vị đã có những triệu chứng nào sau đây hay không?
                            🤒 Fever or chills
                            🤭 Cough
                            😩 Shortness of breath or difficulty breathing
                            😪 Fatigue
                            🤕 Muscle or body aches
                            🤯 Headache
                            😷 New loss of taste or smell
                            😷 Sore throat
                            🤧 Congestion or runny nose
                            🤮 Nausea or vomiting
                            🤢 Diarrhea`
                        }
                      />
                    </li>
                    <li className="my-2">
                      <Question
                        name="question3"
                        questionText={
                          language === "en"
                            ? `Have you had any known contact with someone who tested positive for COVID-19 within the past 2 weeks?`
                            : `Quý vị có tiếp xúc với những ai đã mắc bệnh COVID-19 trong vòng 2 tuần vừa qua hay không?`
                        }
                      />
                    </li>
                    <li className="my-2">
                      <Question
                        name="question4"
                        questionText={
                          language === "en"
                            ? `Have you or anyone in your household tested positive for COVID-19 within the past 2 weeks?`
                            : `Quý vị hoặc có ai trong gia đình đã mắc bệnh COVID-19 trong vòng 2 tuần vừa qua hay không?`
                        }
                      />
                    </li>
                  </ol>

                  <Button
                    isSubmitting={isSubmitting}
                    isDisabled={!formOpen || !(isValid && dirty)}
                    isValid={isValid && dirty}
                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </Button>
                </Form>
              </section>
            </div>
            {showModal ? (
              <ErrorModal
                onClose={() => setShowModal(false)}
                title="Error"
                show={showModal}
              >
                An error occurred, please try again later.
              </ErrorModal>
            ) : null}
          </>
        )}
      </Formik>
    </Layout>
  );
}

export default IndexPage;
