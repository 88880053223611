import React from "react";
import PropTypes from "prop-types";

import { Field, useField } from "formik";

const Question = ({ name, questionText }) => {
  const [field, meta, helpers] = useField(name); //eslint-disable-line

  return (
    <>
      <div
        className={
          meta.touched && meta.error
            ? "text-red-600 whitespace-pre-line"
            : "whitespace-pre-line"
        }
      >
        {questionText}
      </div>

      <div role="group" className="mt-2">
        <label htmlFor={name} className="inline-flex items-center">
          <Field
            type="radio"
            name={name}
            id={name}
            value="yes"
            className="form-radio"
            checked={field.value === "yes"}
          />
          <span className="ml-2">Yes</span>
        </label>
        <label htmlFor={`${name}n`} className="inline-flex items-center ml-5">
          <Field
            type="radio"
            name={name}
            id={`${name}n`}
            value="no"
            className="form-radio"
            checked={field.value === "no"}
          />
          <span className="ml-2">No</span>
        </label>
      </div>
    </>
  );
};

Question.propTypes = {
  name: PropTypes.string.isRequired,
  questionText: PropTypes.string,
};

Question.defaultProps = {
  questionText: "",
};

export default Question;
