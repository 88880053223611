import React from "react";

const Button = ({ isSubmitting, isDisabled, isValid, children }) => {
  let bgClassName =
    "bg-blue-700 border-blue-800 hover:border-blue-700 hover:bg-blue-600";

  if (isSubmitting)
    bgClassName =
      "bg-blue-300 border-blue-400 hover:border-blue-700 hover:bg-blue-600";

  if (isValid) bgClassName = "bg-green-500 border-green-600 hover:bg-green-400";

  if (isDisabled)
    bgClassName =
      "bg-blue-700 border-blue-800 opacity-50 cursor-not-allowed hover:bg-blue-700";

  return (
    <button
      className={`px-4 py-2 mt-4 text-sm font-bold text-white rounded shadow transition duration-100 ${bgClassName}`}
      type="submit"
    >
      {children}
    </button>
  );
};

export default Button;
